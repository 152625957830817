import { Box, Container } from '@material-ui/core';
import React from 'react'
import { useTranslation } from 'react-i18next';
import OfferContComponent from '../../components/OfferContComponent';
import HeroImg from '../../imgs/about.jpg';
import OirpImg from '../../imgs/oirp_logo.png';
import PigImg from '../../imgs/pig_logo.png';
import BluechemImg from '../../imgs/clients/bluechem.png';
// import HolImg from '../../imgs/clients/hol.png';
import SofineImg from '../../imgs/clients/sofine.png';
import TaekpolImg from '../../imgs/clients/taekpol.png';
import AboutSvg from '../../svg/AboutSvg';
import { makeStyles } from '@material-ui/core';
import TitleComponent from '../../components/TitleComponent';
import LinkComponent from '../../components/LinkComponent';
import ClientComponent from './components/ClientComponent';

const useStyles = makeStyles((theme) => ({
    imgCont: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
    },
    collabCont: {
        background: 'white',
        marginTop: 120,
        marginBottom: 120,
        paddingTop: 60,
        paddingBottom: 60,
    },
    collab: {
        display: 'flex',
        gap: 32,
        justifyContent: 'start',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 250,
            flexDirection: 'column',
            margin: 'auto',
            alignItems: 'baseline',
            marginTop: 40,
        },
    },
    client: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 'auto',
        maxWidth: 1100,
        marginTop: 40,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexWrap: 'wrap'
        },
    }
}))

const AboutPage = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <OfferContComponent
            heading={t('about_page.heading')}
            src={HeroImg}
            subPage={false}
            Icon={AboutSvg}
            title={t('about_page.title')}
            content={t('about_page.content')}
            subtitle={t('about_page.subtitle')}
        >
            <Container>
                <Box className={classes.client}>
                    <ClientComponent
                        src={PigImg}
                        alt='PIG'
                    />
                    <ClientComponent
                        src={OirpImg}
                        alt='OIRP Szczecin'
                    />
                </Box>
            </Container>
            <Box className={classes.collabCont}>
                <Container>
                    <TitleComponent title={t('about_page.partners_title')} />
                    <Box className={classes.collab}>
                        <LinkComponent
                            showPoints
                            title={t('about_page.collab_first')}
                        />
                        {/* <LinkComponent
                            showPoints
                            title={t('about_page.collab_second')}
                        /> */}
                        <LinkComponent
                            showPoints
                            title={t('about_page.collab_third')}
                        />
                    </Box>
                </Container>
            </Box>
            <Container>
                <TitleComponent title={t('about_page.collab_title')} />
                <Box className={classes.client}>
                    <ClientComponent
                        src={BluechemImg}
                        to='https://www.blauchem.com/pl'
                        alt='Blau Chem'
                    />
                    <ClientComponent
                        src={TaekpolImg}
                        to='https://www.taekpol.de'
                        alt='Taekpol'
                    />
                    {/* <ClientComponent
                        src={HolImg}
                        to='https://www.holzverpackung-huefingen.com'
                        alt='Holzverpackung Hufingen'
                    /> */}
                    <ClientComponent
                        src={SofineImg}
                        to='https://www.sofine.pl'
                        alt='SoFine Agency'
                    />
                </Box>
            </Container>
        </OfferContComponent>
    )
}


export default AboutPage;
